<template>
  <div class="popup-filter-modified-component">
    <div class="form__body">
      <label class="form-label">Дата изменения</label>
      <div class="form__body-inputBlock d-flex form__body-inputBlock--first">
        <div class="required d-flex align-items-center w-50 justify-content-around">
          <label class="form-label mb-0">От</label>
          <div class="modal-date-container error-position-absolute">
            <dateComponent v-model="filter.modifiedFrom" :clearable="true" :upperLimit="upperLimitFrom" required />
          </div>
        </div>
        <div class="required d-flex align-items-center w-50 justify-content-around">
          <label class="form-label mb-0">До</label>
          <div class="modal-date-container error-position-absolute">
            <dateComponent v-model="filter.modifiedTo" :clearable="true" :lowerLimit="lowerLimitTo" required />
          </div>
        </div>
      </div>
      <div class="form__body-inputBlock mb-3">
        <SelectSingleComponentCheckbox
          v-model="filter.tnvedId"
          placeholder="Выберите товар"
          trackBy="text"
          valueProp="id"
          labelOptions="text"
          :resolveOnLoad="false"
          delay="1000"
          minChars="1"
          :options="
            async function (query) {
              return await fetchSearchNSITnved(query, 6);
            }
          "
          @changeSelect="onTnvedSelect"
        />
      </div>
      <div class="form__body-inputBlock mb-3">
        <SelectSingleComponentCheckbox
          v-model="filter.tnvedBeforeCode"
          placeholder="Выберите Код ТН ВЭД ЕАЭС"
          trackBy="text"
          valueProp="id"
          labelOptions="text"
          :resolveOnLoad="false"
          delay="1000"
          minChars="1"
          :options="
            async function (query) {
              return await fetchSearchNSITnved(query, 10);
            }
          "
        />
      </div>
      <div class="form__body-inputBlock mb-3">
        <SelectSingleComponentCheckbox
          v-model="filter.initiator"
          placeholder="Выберите инициатора"
          trackBy="text"
          valueProp="id"
          labelOptions="text"
          :options="initiators"
        />
      </div>
      <div class="form__body-inputBlock mb-3">
        <ComponentInput
          label="Номер решения"
          v-model="filter.decisionNumber"
          type="text"
          placeholder="Введите номер решения"
        />
      </div>
    </div>
    <div class="popup-filter-modified-component__button">
      <ButtonComponent @click="filterData" mod="gradient-bg" class="button-redesign--popup-measure"
        >Показать</ButtonComponent
      >
      <ButtonComponent @click="close" mod="transparent-bg" class="button-redesign--popup-measure"
        >Закрыть</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Constants from '@/common/constants';

  import Api from '@/modules/measure/api/singlettr';

  import { NSITNVED_ACTIONS_NAME, NSITNVED_GETTERS_NAME } from '@/store/nsitnved';

  export default {
    name: 'SingleTtrFilterModifiedComponent',
    components: { ComponentInput, dateComponent, ButtonComponent, SelectSingleComponentCheckbox },
    emits: ['filter', 'close'],
    data() {
      return {
        filter: {
          modifiedFrom: null,
          modifiedTo: null,
          tnvedId: null,
          tnvedBeforeCode: null,
          initiator: null,
          decisionNumber: null,
        },
        tnvedName: '',
        initiators: [],
      };
    },
    created() {
      this.loadDictionaries();
    },
    computed: {
      ...mapGetters({ getSearchNSITnved: NSITNVED_GETTERS_NAME.getSearchNSITnved }),
      upperLimitFrom() {
        if (this.filter.modifiedTo) {
          return moment(this.filter.modifiedTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date(2099, 0, 1);
      },
      lowerLimitTo() {
        if (this.filter.modifiedFrom) {
          return moment(this.filter.modifiedFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return '';
      },
    },
    methods: {
      ...mapActions({ searchNSITnved: NSITNVED_ACTIONS_NAME.searchNSITnved }),
      loadDictionaries() {
        Api.getInitiators().then((response) => {
          this.initiators = response.data.map((x) => ({ id: x, text: x }));
        });
      },
      filterData() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        this.$emit('filter', this.filter, this.getFilterText());
      },
      close() {
        this.$emit('close');
      },
      getFilterText() {
        let result =
          'Период: ' +
          moment(this.filter.modifiedFrom).format('DD.MM.YYYY') +
          ' - ' +
          moment(this.filter.modifiedTo).format('DD.MM.YYYY') +
          (this.tnvedName ? ', товар: ' + this.tnvedName : '') +
          (this.filter.tnvedBeforeCode ? ', код: ' + this.filter.tnvedBeforeCode : '') +
          (this.filter.initiator ? ', инициатор: ' + this.filter.initiator : '') +
          (this.filter.decisionNumber ? ', номер решения: ' + this.filter.decisionNumber : '');

        return result;
      },
      onTnvedSelect(e) {
        this.tnvedName = e?.value?.text;
      },
      async fetchSearchNSITnved(query, codeLength) {
        const params = {
          pageNumber: 1,
          pageSize: 50,
          codeLength,
          term: query,
        };
        const res = await this.searchNSITnved(params);
        const { data } = res;
        return data.items.map((i) => ({ id: i.id, text: i.code + ' - ' + i.name }));
      },
      /**
       * @public
       * Вызывается в родительском компоненте через ref
       */
      reset() {
        this.filter.modifiedFrom = null;
        this.filter.modifiedTo = null;
        this.filter.tnvedId = null;
        this.filter.tnvedBeforeCode = null;
        this.filter.initiator = null;
        this.filter.decisionNumber = null;
        this.v$.$reset();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        filter: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .popup-filter-modified-component {
    &__button {
      display: flex;
      gap: 8px;
    }
  }
  .modal-date-container::v-deep {
    .v3dp__popout {
      right: unset;
      position: fixed !important;
      z-index: 11110 !important;
    }
  }
  .form-label-no-margin {
    margin-bottom: 0;
  }
  .form__body-inputBlock {
    margin-bottom: 10px !important;
    padding-bottom: 10px !important;

    &--first {
      padding-bottom: 20px !important;
    }
  }
</style>
